import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './App.css'; // Import der CSS-Datei für Styling

function App() {
  return (
    <div className="App">
      <div className="thank-you-message">
        <p>
          Ihr Lieben!!<br />
          Ich möchte mich ganz herzlich für Euer Kommen bedanken. <br />
          Danke an diejenigen, die nicht konnten, aber mich mit tollen Nachrichten erfreut haben.<br />
          Danke an Sascha, der sich ab Dezember sehr auf die neu gewonnene Zeit mit mir freut 😬🥰<br />
          Danke an Jan und Nils, die beim Halbmarathonkurs früher unterstützt haben und mir die Zeit gegeben haben (ich denke, dass es eine win-win Situation war)🤣<br />
          Danke an Maik, der mir viele Jahre (seit 2018) meine Trainingspläne geschrieben und mich an den Rande des Wahnsinns getrieben hat 😉.<br />
          Danke an tolle 10 Jahre und wir sehen uns vielleicht beim Lauftreff!<br />
          Es dauert noch etwas bis er startet, aber alle die vergessen haben, ihre Namen auf die Liste einzutragen, meldet Euch gerne bei mir ❤️<br />
          Wir sehen uns bestimmt noch neben der Laufstrecke.<br />
          Liebe Grüße<br />
          Eure Mel 😍😘
        </p>
      </div>
    </div>
  );
}

export default App;
